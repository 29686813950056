import { Select } from 'antd'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'
import {
  DispatchEvent,
  HTMLElementEvent,
  SelectOptionSingle,
} from '../../types'
import { pushBinding } from '../binding_controller'
import { dispatcher } from '../pub_controller'
import { ComponentController } from './component_controller'

export class SelectController extends ComponentController<SelectProps> {
  static targets = ['component', 'input']

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement

  update(event: DispatchEvent<{ content: SelectOptionSingle }>) {
    const newProps = this.getPropsFromEvent(event)
    const value = newProps['value']
    const newOptions = newProps['options'] || this.props.options
    const selectedOption = this.getSelectedOption(newOptions, value)

    if (value && !selectedOption) {
      // could apply error styling here if desired; should only happen on remote update
      console.log(`[select] error: no such option ${value}`)
      return
    }

    this.updateTargets(selectedOption, { pubQuiet: newProps['pubQuiet'] })
    delete newProps['pubQuiet']
    this.updateProps(newProps)
  }

  get component() {
    return Select
  }

  get initialProps() {
    const props = this.propsFromData
    const initialValue = this.inputTarget.value
      ? this.getSelectedOption(props.options, this.inputTarget.value)
      : undefined

    return {
      ...props,
      defaultValue: initialValue,
      onChange: this.onChange.bind(this),
      value: initialValue,
      virtual: false,
    }
  }

  onChange(value: string): void {
    const selectedOption = this.getSelectedOption(this.props.options, value)

    if (value && !selectedOption) {
      // could apply error styling here if desired; should only happen on remote update
      console.log(`[select] error: no such option ${value}`)
      return
    }

    this.updateTargets(selectedOption)
    this.updateProps({ value })

    // to be culled
    pushBinding({
      controller: this,
      attribute: 'change',
      value: selectedOption,
      prefix: 'select',
    })
  }

  get rootElement() {
    return this.componentTarget
  }

  private getSelectedOption(
    options: DefaultOptionType[],
    value: any,
  ): DefaultOptionType {
    return options.find((opt: DefaultOptionType) => opt.value == value)
  }

  private updateTargets(selected: DefaultOptionType, options = {}): void {
    this.inputTarget.value = selected ? `${selected.value}` : ''

    if (!options['pubQuiet']) {
      this.inputTarget.dispatchEvent(new Event('change'))
    }

    const pubChannel = this.inputTarget.getAttribute('data-pub-channel-value')
    const pubAs = this.inputTarget.getAttribute('data-pub-as-value')

    if (pubChannel && pubAs && selected && !options['pubQuiet']) {
      dispatcher(this, `${pubAs}_text`, pubChannel, `${selected.label}`)
    }
  }
}
