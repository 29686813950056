import { PhotoswipeController } from './photoswipe_controller'

type Image = {
  filename: string
  url: string
  thumbnailUrl: string
  width: string
  height: string
}

type PersistedImage = {
  id: string
} & Image

export class ImagesFieldController extends PhotoswipeController {
  static targets = ['hiddenInputs', 'input', 'previews']
  static values = {
    ...super.values,
    fieldName: String,
    persistedImages: Array,
    removeIcon: String,
    removeIconAlt: String,
  }

  declare readonly hiddenInputsTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement & { files: FileList }
  declare readonly previewsTarget: HTMLElement
  declare fieldNameValue: string
  declare files: File[]
  declare persistedImagesValue: PersistedImage[]
  declare removeIconValue: string
  declare removeIconAltValue: string

  connect() {
    super.connect()
    // Event listener set up here rather than the dom (with actions) because:
    //   1) this component will not work without a linked file input
    //   2) there is no replacement for a file input functionally
    //   3) the file input cannot be styled so is hidden
    // So there is no meaningfully different alternative implementation,
    // and providing apparent flexibility only allows for misuse.
    this.inputTarget.addEventListener(
      'change',
      this.handleFilesSelected.bind(this),
    )
    this.renderPersistedImages()
    this.files = []
  }

  disconnect() {
    super.disconnect()
    this.inputTarget.removeEventListener(
      'change',
      this.handleFilesSelected.bind(this),
    )
  }

  defaultOptions() {
    return {
      children: '.images-field__preview-item',
    }
  }

  imageDimensions(file: File): Promise<{ width: string; height: string }> {
    return new Promise((resolve) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () =>
        resolve({
          width: image.width.toString(),
          height: image.height.toString(),
        })
    })
  }

  hiddenInput(image: PersistedImage) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.fieldNameValue
    input.value = image.id

    return input
  }

  imagePreview(image: Image, onRemove: () => void) {
    const li = document.createElement('li')
    li.className = 'images-field__preview-item'

    const fileName = this.photoswipeAnchor(image.url, image.width, image.height)
    fileName.className = 'images-field__preview-file-name'
    fileName.textContent = image.filename

    const img = document.createElement('img')
    img.className = 'images-field__preview-image'
    img.src = image.thumbnailUrl
    img.alt = ''

    const imgAnchor = this.photoswipeAnchor(
      image.url,
      image.width,
      image.height,
    )
    imgAnchor.ariaHidden = 'true'
    imgAnchor.tabIndex = -1
    imgAnchor.appendChild(img)

    const removeButton = document.createElement('button')
    removeButton.className = 'images-field__preview-remove'
    removeButton.addEventListener('click', () => {
      li.remove()
      onRemove()
    })
    const deleteIcon = document.createElement('img')
    deleteIcon.src = this.removeIconValue
    deleteIcon.alt = this.removeIconAltValue
    removeButton.appendChild(deleteIcon)

    li.appendChild(imgAnchor)
    li.appendChild(fileName)
    li.appendChild(removeButton)

    return li
  }

  renderPersistedImages() {
    this.persistedImagesValue.forEach((image) => {
      const input = this.hiddenInput(image)
      this.hiddenInputsTarget.appendChild(input)

      const removeInput = () => input.remove()
      const preview = this.imagePreview(image, removeInput)
      this.previewsTarget.appendChild(preview)
    })
  }

  handleFilesSelected() {
    const newFiles = Array.from(this.inputTarget.files)
    newFiles.forEach(this.processSelectedFile.bind(this))
    this.files = [...this.files, ...newFiles]
    this.inputTarget.files = this.convertToFilesList(this.files)
  }

  processSelectedFile(file: File) {
    const reader = new FileReader()
    const removeFile = () => {
      this.files = this.files.filter((f) => f !== file)
      this.inputTarget.files = this.convertToFilesList(this.files)
    }

    reader.onload = async (e) => {
      const { width, height } = await this.imageDimensions(file)
      const url = e.target.result as string

      const li = this.imagePreview(
        {
          filename: file.name,
          url,
          thumbnailUrl: url,
          width,
          height,
        },
        removeFile,
      )

      this.previewsTarget.appendChild(li)
    }

    reader.readAsDataURL(file)
  }

  convertToFilesList(files: File[]): FileList {
    const dataTransfer = new DataTransfer()
    files.forEach((file) => dataTransfer.items.add(file))
    return dataTransfer.files
  }
}
