import { Controller } from '@hotwired/stimulus'
import { HTMLElementEvent } from '../types'

export const dispatcher = (
  controller: Controller,
  pubAs: string,
  pubChannel: string,
  pubDetail: string | object,
): void => {
  const detail =
    typeof pubDetail === 'string' ? { value: pubDetail } : pubDetail

  console.log(`[dispatch] ${pubChannel}:${pubAs}`)
  console.log(detail)

  controller.dispatch(pubAs, { prefix: pubChannel, detail })
}

export class PubController extends Controller {
  static values = { as: String, channel: String, on: String }

  asValue: string
  channelValue: string
  onValue: string

  connect() {
    if (this.channelValue) {
      this.element.addEventListener(this.onValue, this)
    }
  }

  disconnect() {
    if (this.channelValue) {
      this.element.removeEventListener(this.onValue, this)
    }
  }

  handleEvent(event: HTMLElementEvent<HTMLInputElement>): void {
    const detail = { event, value: event.target.value }
    dispatcher(this, this.asValue, this.channelValue, detail)
  }
}
