import { Controller } from '@hotwired/stimulus'

export class CountyController extends Controller {
  static values = {
    visible: String,
  }

  declare visibleValue: string

  toggleDisplay(event) {
    if (event.detail.value == this.visibleValue) {
      this.element.classList.remove('invisible')
    } else {
      this.element.classList.add('invisible')
    }
  }
}
