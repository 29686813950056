import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'

import {
  DispatchEvent,
  HTMLElementEvent,
  MapboxAddressFeatureCoordinates,
  StateOrProvince,
} from '../types'
import { MapboxAddress } from '../models'
import { LocalClient } from '../clients'
import { targetUpdate } from './components/component_controller'
import { MapboxController } from './mapbox_controller'

export class VerifiedAddressController extends MapboxController {
  static targets = [
    'city',
    'county',
    'country',
    'destroyFlag',
    'lineOne',
    'lineTwo',
    'postalCode',
    'stateOrProvince',
    'timeZone',
    'latlong',
  ]

  static values = {
    pubChannel: {
      type: String,
      default: 'address',
    },
  }

  declare readonly cityTarget: HTMLInputElement
  declare readonly hasCityTarget: boolean
  declare readonly countyTarget: HTMLInputElement
  declare readonly hasCountyTarget: boolean
  declare readonly countryTarget: HTMLSelectElement
  declare readonly hasCountryTarget: boolean
  declare readonly destroyFlagTarget: HTMLInputElement
  declare readonly lineOneTarget: HTMLInputElement
  declare readonly hasLineOneTarget: boolean
  declare readonly lineTwoTarget: HTMLInputElement
  declare readonly hasLineTwoTarget: boolean
  declare readonly postalCodeTarget: HTMLInputElement
  declare readonly hasPostalCodeTarget: boolean
  declare readonly stateOrProvinceTarget: HTMLSelectElement
  declare readonly hasStateOrProvinceTarget: boolean
  declare readonly timeZoneTarget: HTMLSelectElement
  declare readonly hasTimeZoneTarget: boolean
  declare readonly latlongTarget: HTMLInputElement
  declare readonly hasLatlongTarget: boolean

  declare readonly pubChannelValue: string

  connect() {
    this.setTokens()
    this.element.addEventListener('change', this.clearDestroyFlag)
  }

  disconnect() {
    this.element.removeEventListener('change', this.clearDestroyFlag)
  }

  handleSuggestedAddressSelectionEvent({
    detail: address,
  }: DispatchEvent<MapboxAddress>) {
    this.setAddressFields(address)
  }

  async handleCountryCodeSelectionEvent({ detail }: DispatchEvent<string>) {
    const currentValue = this.stateOrProvinceTarget.querySelector('input').value
    const options = await this.fetchStateOrProvinceOptions(detail.value)
    const value = options.map((o) => o.value).includes(currentValue)
      ? currentValue
      : ''

    this.setAddressStateOrProvinceValue(value, options)
  }

  async fetchSuggestedAddressDetails(event: HTMLElementEvent<HTMLElement>) {
    const { id, json } = event.target.dataset
    const { context, full_address } = JSON.parse(json)

    const forwardGeocodeAddressPayload =
      await LocalClient.fetchSuggestedAddressDetails(full_address)

    const addressProperties =
      forwardGeocodeAddressPayload.features[0]?.properties

    const address = new MapboxAddress(
      addressProperties.mapbox_id || id,
      addressProperties.context || context,
      addressProperties.coordinates,
    )

    this.dispatch('address-verified', { detail: address })
  }

  async fetchStateOrProvinceOptions(countryCode: string) {
    const { options } =
      await LocalClient.getStatesAndProvincesFromCountryCode(countryCode)
    return options
  }

  clear() {
    this.clearAddressFields()
  }

  private clearAddressFields() {
    this.setAddressLineOneValue('')
    this.setAddressLineTwoValue('')
    this.setAddressCityValue('')
    if (this.hasCountyTarget) {
      this.setAddressCountyValue('')
    }
    this.setAddressCountryValue('')
    this.setAddressStateOrProvinceValue('', [])
    this.setAddressPostalCodeValue('')
    this.setAddressTimeZoneValue(null)
    this.setAddressLatlong(null)
    this.setDestroyFlag()

    this.dispatch('address-cleared')
  }

  private async setAddressFields(address: MapboxAddress) {
    const stateOrProvinceOptions = await this.fetchStateOrProvinceOptions(
      address.country,
    )

    this.setAddressLineOneValue(address.lineOne)
    this.setAddressCityValue(address.city)
    this.setAddressCountyValue(address.county)
    this.setAddressCountryValueQuietly(address.country)
    this.setAddressStateOrProvinceValue(
      address.stateOrProvince,
      stateOrProvinceOptions,
    )
    this.setAddressPostalCodeValue(address.postalCode)
    this.setAddressTimeZoneValue(address.coordinates)
    this.setAddressLatlong(address.latlong)
  }

  private setAddressLineOneValue(value: string) {
    this.lineOneTarget.value = value
    this.lineOneTarget.dispatchEvent(new Event('blur'))
  }

  private setAddressLineTwoValue(value: string) {
    this.lineTwoTarget.value = value
    this.lineTwoTarget.dispatchEvent(new Event('blur'))
  }

  private setAddressCityValue(value: string) {
    this.cityTarget.value = value
    this.cityTarget.dispatchEvent(new Event('blur'))
  }

  private setAddressCountyValue(value: string) {
    if (this.hasCountyTarget) {
      this.countyTarget.value = value
      this.countyTarget.dispatchEvent(new Event('blur'))
    }
  }

  private setAddressStateOrProvinceValue(
    value: string,
    options: StateOrProvince[],
  ) {
    targetUpdate(this.stateOrProvinceTarget, { value, options })
  }

  private setAddressPostalCodeValue(value: string) {
    this.postalCodeTarget.value = value
    this.postalCodeTarget.dispatchEvent(new Event('blur'))
  }

  private setAddressCountryValue(value: string) {
    targetUpdate(this.countryTarget, { value })
  }

  private setAddressCountryValueQuietly(value: string) {
    targetUpdate(this.countryTarget, { value, pubQuiet: true })
  }

  private setAddressTimeZoneValue(
    coordinates: MapboxAddressFeatureCoordinates,
  ) {
    if (this.hasTimeZoneTarget) {
      if (coordinates) {
        const timeZone = getFuzzyLocalTimeFromPoint(Date.now(), [
          coordinates.longitude,
          coordinates.latitude,
        ])
        targetUpdate(this.timeZoneTarget, { value: timeZone._z.name })
      } else {
        targetUpdate(this.timeZoneTarget, { value: '' })
      }
    }
  }

  private setAddressLatlong(value: string) {
    if (this.hasLatlongTarget) {
      this.latlongTarget.value = value
      this.latlongTarget.dispatchEvent(new Event('blur'))
    }
  }

  private setDestroyFlag() {
    this.destroyFlagTarget.value = 'true'
  }

  private clearDestroyFlag = () => {
    this.destroyFlagTarget.value = 'false'
  }
}
