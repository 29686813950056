import {
  MapboxAddressFeatureContext,
  MapboxAddressFeatureCoordinates,
} from '../types'

export class MapboxAddress {
  constructor(
    public mapbox_id: string,
    public context: MapboxAddressFeatureContext,
    public coordinates?: MapboxAddressFeatureCoordinates,
  ) {}

  get lineOne(): string {
    return this.context.address.name
  }

  get city(): string {
    return this.context.place.name
  }

  get county(): string {
    return this.context.district?.name || ''
  }

  get stateOrProvince(): string {
    return this.context.region.region_code
  }

  get postalCode(): string {
    return this.context.postcode.name
  }

  get country(): string {
    return this.context.country.country_code
  }

  get latitude(): string {
    return this.coordinates?.latitude.toString() || ''
  }

  get longitude(): string {
    return this.coordinates?.longitude.toString() || ''
  }

  get latlong(): string | undefined {
    if (this.coordinates) {
      return `${this.latitude},${this.longitude}`
    }
  }
}
