import { InputNumber, InputNumberProps } from 'antd'

import { ComponentController } from './component_controller'

export class InputNumberController extends ComponentController<InputNumberProps> {
  static values = { default: String, id: String, name: String }

  declare readonly defaultValue: string
  declare readonly idValue: string
  declare readonly nameValue: string

  get initialProps() {
    const props = this.propsFromData

    return {
      ...props,
      type: 'number',
      defaultValue: this.defaultValue,
      id: this.idValue,
      name: this.nameValue,
    }
  }

  get component() {
    return InputNumber
  }
}
