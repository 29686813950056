import { Controller } from '@hotwired/stimulus'

export class Search extends Controller<HTMLInputElement> {
  static values = {
    contentType: {
      type: String,
      default: 'text/html',
    },
    multi: {
      type: Boolean,
      default: false,
    },
    name: String,
    urlMappings: Object,
  }
  declare readonly contentTypeValue: string
  declare readonly multiValue: boolean
  declare readonly nameValue: string
  declare readonly urlMappingsValue: object

  async fetch(event: any) {
    const value = event.detail.value || event.target.value
    const nameParam = this.multiValue
      ? value.map((v: any) => v.value).join(',')
      : value?.value || value[0]?.value || value

    for (const [identifier, url] of Object.entries(this.urlMappingsValue)) {
      const response = await fetch(`${url}?${this.nameValue}=${nameParam}`)

      if (response.ok) {
        const content =
          this.contentTypeValue === 'application/json'
            ? await response.json()
            : await response.text()

        this.dispatch(`${identifier}-results`, { detail: { content } })
      }
    }
  }
}
