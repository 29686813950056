import { Controller } from '@hotwired/stimulus'

export class UsersController extends Controller {
  static targets = ['checkbox']

  // Define the type for checkboxTargets, which is an array of HTMLInputElements
  declare readonly checkboxTargets: HTMLInputElement[]

  checkAll(event: PointerEvent) {
    this.checkboxTargets.forEach((checkbox) => {
      const target = event.target as HTMLInputElement
      if (checkbox.checked !== target.checked) {
        checkbox.click() // This triggers the checkbox's click event, toggling its state
      }
    })
  }

  toggleBulkActions() {
    const selectedValues = Array.from(this.checkboxTargets)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)

    if (selectedValues.length > 1) {
      document
        .querySelector('#bulk-action-menu')
        .classList.remove('visually-hidden')
    } else {
      document
        .querySelector('#bulk-action-menu')
        .classList.add('visually-hidden')
    }
  }
}
