import { Controller } from '@hotwired/stimulus'

export class CollapsibleAddressesController extends Controller {
  static targets = ['billing', 'shipping']

  className = 'addresses--collapsed'

  declare billingTarget: HTMLElement
  declare shippingTarget: HTMLElement

  toggleBilling() {
    this.billingTarget.classList.toggle(this.className)
  }

  toggleShipping() {
    this.shippingTarget.classList.toggle(this.className)
  }
}
