import { Controller } from '@hotwired/stimulus'

import { latlongRegExp } from '../utils/mapbox'
import { DispatchEvent, HTMLElementEvent } from '../types'
import { MapboxAddress } from '../models'
import { dispatcher } from './pub_controller'

export class GeocodeableAddressableInputsController extends Controller {
  static targets = ['addressLatlong', 'latlong', 'latlongError']
  declare readonly addressLatlongTarget: HTMLInputElement
  declare readonly hasAddressLatlongTarget: boolean
  declare readonly latlongTarget: HTMLInputElement
  declare readonly hasLatlongTarget: boolean
  declare readonly latlongErrorTarget: HTMLSpanElement
  declare readonly hasLatlongErrorTarget: boolean

  connect() {
    this.latlongTarget.onkeyup = this.updateLatLongError.bind(this)

    setTimeout(() => {
      if (this.hasAddressLatlongTarget) {
        // wait until other controllers are connected and can receive events
        this.dispatch('address-latlong-change', {
          detail: this.addressLatlongTarget.value,
        })
      }

      this.updateLatlong()
    }, 500)
  }

  handleSuggestedAddressSelectionEvent({
    detail: address,
  }: DispatchEvent<MapboxAddress>) {
    this.setLatlongValue(address.latlong)
    this.setAddressLatlongValue(address.latlong)
  }

  handleLatlongChangeEvent({ detail: latlong }: DispatchEvent<string>) {
    this.setLatlongValue(latlong)
    dispatcher(this, 'latlong', 'address', latlong)
  }

  handleAddressClearedEvent() {
    this.setAddressLatlongValue('')
    this.setLatlongValue('')
  }

  updateLatlong() {
    if (this.isOverrideValid) {
      this.dispatch('latlong-change', { detail: this.latlongTarget.value })
    }
  }

  private validateCoordinates(latlong: string) {
    return latlongRegExp.test(latlong)
  }

  private setLatlongValue(latlong: string) {
    this.latlongTarget.value = latlong
  }

  private setAddressLatlongValue(latlong: string) {
    if (latlong && this.hasAddressLatlongTarget) {
      this.addressLatlongTarget.value = latlong
    }
  }

  private get verifiedAddressLatLng() {
    return this.hasAddressLatlongTarget ? this.addressLatlongTarget.value : ''
  }

  private get overrideLatLng() {
    return this.latlongTarget.value
  }

  private get latitude() {
    return this.latlongParts()[0]
  }

  private get longitude() {
    return this.latlongParts()[1]
  }

  private get addressLatitude() {
    return this.hasAddressLatlongTarget
      ? this.latlongParts(this.addressLatlongTarget)[0]
      : ''
  }

  private get addressLongitude() {
    return this.hasAddressLatlongTarget
      ? this.latlongParts(this.addressLatlongTarget)[1]
      : ''
  }

  private get isVerifiedValid(): boolean {
    return this.validateCoordinates(this.verifiedAddressLatLng)
  }

  private get isOverrideValid(): boolean {
    return this.validateCoordinates(this.overrideLatLng)
  }

  private latlongParts({ value }: HTMLInputElement = this.latlongTarget) {
    return value.split(',')
  }

  private updateLatLongError(e: HTMLElementEvent<HTMLInputElement>) {
    if (e.target.value.length === 0) {
      this.clearLatLongError()
    } else if (this.validateCoordinates(e.target.value)) {
      this.clearLatLongError()
    } else {
      this.showLatLongError()
    }
  }

  private clearLatLongError() {
    this.latlongErrorTarget.classList.add('hidden')
  }

  private showLatLongError() {
    this.latlongErrorTarget.classList.remove('hidden')
  }
}
