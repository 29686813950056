import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import { ComponentController } from './component_controller'
import { DispatchEvent } from '../../types'
import { dispatcher } from '../pub_controller'

export class DateRangePickerController extends ComponentController<RangePickerProps> {
  static targets = ['component', 'input']

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement

  get component() {
    return DatePicker.RangePicker
  }

  get initialProps() {
    const props = this.propsFromData
    const url = new URL(window.location.href)
    const startDate = props['start_date'] || url.searchParams.get('start_date')
    const endDate = props['end_date'] || url.searchParams.get('end_date')

    let initialValue
    if (startDate || endDate) {
      initialValue = [
        startDate ? dayjs(startDate) : null,
        endDate ? dayjs(endDate) : null,
      ]
    }

    return {
      ...props,
      onChange: this.onChange.bind(this),
      value: initialValue,
    }
  }

  get rootElement() {
    return this.componentTarget
  }

  update(event: DispatchEvent<{ content: [string, string] }>) {
    const value = this.getPropsFromEvent(event)['value']
    this.updateTargets(value)
    super.update(event)
  }

  onChange(dates: [any, any], dateStrings: [string, string]): void {
    this.updateTargets(dates)
    this.updateProps({ value: dates })

    const startDateInput = document.getElementById(
      this.props['start_date_field'] + '-hidden',
    ) as HTMLInputElement
    const endDateInput = document.getElementById(
      this.props['end_date_field'] + '-hidden',
    ) as HTMLInputElement

    if (startDateInput) {
      startDateInput.value = dayjs(dateStrings[0]).format('YYYY-MM-DD')
    }
    if (endDateInput) {
      endDateInput.value = dayjs(dateStrings[1]).format('YYYY-MM-DD')
    }

    this.rootElement.closest('form')?.dispatchEvent(new Event('submit'))
  }

  reset() {
    this.updateProps({ value: [null, null] })
  }

  private updateTargets(dates: [string | null, string | null]): void {
    const valueString = dates.filter(Boolean).join(',')
    this.inputTarget.value = valueString
    this.inputTarget.dispatchEvent(new Event('change'))

    const pubChannel = this.inputTarget.getAttribute('data-pub-channel-value')
    const pubAs = this.inputTarget.getAttribute('data-pub-as-value')

    if (pubChannel && pubAs) {
      dispatcher(this, `${pubAs}_text`, pubChannel, dates)
    }
  }
}
