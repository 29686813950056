import { Controller } from '@hotwired/stimulus'
import { DispatchEvent } from '../types'

export type Binding = {
  controller: Controller
  attribute: string
  value: any
  prefix?: string
}

export class BoundValue<T> {
  value: T

  constructor(value: T) {
    this.value = value
  }
}

export const pushBinding = ({
  controller,
  attribute,
  value,
  prefix,
}: Binding): void => {
  console.log(
    '[WARNING] pushBinding is deprecated, do not use in new features (see SHEP-1020)',
  )
  controller.dispatch(`${attribute}`, {
    prefix: prefix,
    detail: new BoundValue(value),
  })
}

export class BindingController extends Controller {
  connect() {
    console.log('[binding] connect')
    console.log(this.element)
  }

  extract(event: DispatchEvent<{ detail: BoundValue<string> }>): string {
    return event.detail.value
  }

  sync(value: string): void {
    this.element.innerHTML = value
  }

  update(event: DispatchEvent<{ detail: BoundValue<string> }>) {
    console.log('[binding] update')
    this.sync(this.extract(event))
  }
}
