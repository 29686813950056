import { Controller } from '@hotwired/stimulus'
import { targetUpdate } from './components/component_controller'
import { DispatchEvent, HTMLElementEvent } from '../types'
import { dispatcher } from './pub_controller'

interface StimulusEvent<T> extends Event {
  detail: {
    value: T
  }
}

export class LocationFormController extends Controller {
  static targets = ['organizationDependentSelect']

  declare readonly organizationDependentSelectTargets: HTMLElement[]

  dispatchNameChangeEvent({
    target: { value },
  }: HTMLElementEvent<HTMLInputElement>) {
    dispatcher(this, 'name', 'address', value)
  }

  dispatchStateProvinceChangeEvent(event: DispatchEvent) {
    const stateOrProvince = event.detail.value.label
    dispatcher(this, 'state_or_province', 'address', stateOrProvince)
  }

  dispatchCountryCodeChangeEvent(event: DispatchEvent) {
    const countryCode = event.detail.value.label
    dispatcher(this, 'country_code', 'address', countryCode)
  }

  dispatchPrimaryPointOfContactDetailChangeEvent(event: DispatchEvent) {
    const contactDetail = event.detail.value.label

    dispatcher(this, 'contact_name', 'address', contactDetail)
  }

  updateOrganization(event: StimulusEvent<string>) {
    const organization = event.detail.value
    const disabled = organization === ''
    this.organizationDependentSelectTargets.forEach((target) => {
      targetUpdate(target, { disabled })
    })
  }
}
