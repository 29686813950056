import { Controller } from '@hotwired/stimulus'
import { DispatchEvent, Location, SelectableUser } from '../types'
import { LocalClient } from '../clients'
import { dispatcher } from './pub_controller'
import { targetUpdate } from './components/component_controller'

export class SiteForm extends Controller {
  static targets = [
    'latlongInput',
    'locationSelect',
    'primaryPointOfContact',
    'coordinators',
    'inspectors',
    'responders',
    'tagSelect',
  ]

  declare readonly latlongInputTarget: HTMLInputElement
  declare readonly locationSelectTarget: HTMLInputElement
  declare readonly hasLatlongInputTarget: boolean
  declare readonly primaryPointOfContactTarget: HTMLInputElement
  declare readonly hasPrimaryPointOfContactTarget: boolean
  declare readonly coordinatorsTarget: HTMLElement
  declare readonly inspectorsTarget: HTMLElement
  declare readonly respondersTarget: HTMLElement
  declare readonly tagSelectTarget: HTMLElement
  declare client: LocalClient

  connect() {
    this.initializeClient()
  }

  async handleLocationSelect(event: DispatchEvent) {
    const location_id = event.detail.value.value
    let location: Location

    if (location_id) {
      location = await this.client.fetchLocation(location_id)
    }

    // we set other fields on the form imperatively and rely on their pub controllers
    // to broadcast those values to the window if necessary
    this.setLatLong(location.latlong)
    this.setPrimaryContact(location.primary_point_of_contact)
    this.setCoordinators(location.coordinators)
    this.setInspectors(location.inspectors)
    this.setResponders(location.responders)

    // dispatcher only used here because there are no corresponding DOM fields
    // for these values in the site form; if there were we could just use standard
    // pub controller style
    dispatcher(
      this,
      'contact_name',
      'site',
      location.primary_point_of_contact.name,
    )
    dispatcher(this, 'latlong', 'site', location.latlong)
    dispatcher(
      this,
      'address_line_one',
      'site',
      location.physical_address.line_one,
    )
    dispatcher(
      this,
      'address_line_two',
      'site',
      location.physical_address.line_two,
    )
    dispatcher(this, 'city', 'site', location.physical_address.city)
    dispatcher(
      this,
      'state_or_province',
      'site',
      location.physical_address.state_or_province,
    )
    dispatcher(
      this,
      'postal_code',
      'site',
      location.physical_address.postal_code,
    )
    dispatcher(
      this,
      'country_code',
      'site',
      location.physical_address.country_code,
    )
  }

  handleOrganizationSelect(event: DispatchEvent) {
    const organization_id = event.detail.value
    const disabled = organization_id === ''
    targetUpdate(this.coordinatorsTarget, { disabled })
    targetUpdate(this.inspectorsTarget, { disabled })
    targetUpdate(this.locationSelectTarget, { disabled })
    targetUpdate(this.respondersTarget, { disabled })
    targetUpdate(this.tagSelectTarget, { disabled })
  }

  private setLatLong(latlong: string) {
    this.latlongInputTarget.value = latlong || ''
  }

  private setPrimaryContact(primary_point_of_contact: SelectableUser) {
    const value = primary_point_of_contact.uuid
    targetUpdate(this.primaryPointOfContactTarget, { value })
  }

  private setCoordinators(coordinators: SelectableUser[]): void {
    const value = coordinators.map((u) => u.uuid)
    targetUpdate(this.coordinatorsTarget, { value })
  }

  private setInspectors(inspectors: SelectableUser[]): void {
    const value = inspectors.map((u) => u.uuid)
    targetUpdate(this.inspectorsTarget, { value })
  }

  private setResponders(responders: SelectableUser[]): void {
    const value = responders.map((u) => u.uuid)
    targetUpdate(this.respondersTarget, { value })
  }

  private initializeClient() {
    this.client = new LocalClient()
  }
}
