import { Controller } from '@hotwired/stimulus'

type Document = {
  filename: string
  type: string
}

type PersistedDocument = {
  id: string
} & Document

export class DocumentsFieldController extends Controller {
  static targets = ['hiddenInputs', 'input', 'previews']
  static values = {
    docIcon: String,
    fieldName: String,
    imageIcon: String,
    persistedDocuments: Array,
    pdfIcon: String,
    removeIcon: String,
    removeIconAlt: String,
  }
  fileTypeToIcon = {
    'application/pdf': 'pdf',
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'image/png': 'image',
    'image/heic': 'image',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'doc',
  }
  documentIndex = 0

  declare readonly hiddenInputsTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement & { files: FileList }
  declare readonly previewsTarget: HTMLElement
  declare readonly docIconValue: string
  declare fieldNameValue: string
  declare readonly imageIconValue: string
  declare readonly pdfIconValue: string
  declare persistedDocumentsValue: PersistedDocument[]
  declare removeIconValue: string
  declare removeIconAltValue: string

  connect() {
    super.connect()
    // Event listener set up here rather than the dom (with actions) because:
    //   1) this component will not work without a linked file input
    //   2) there is no replacement for a file input functionally
    //   3) the file input cannot be styled so is hidden
    // So there is no meaningfully different alternative implementation,
    // and providing apparent flexibility only allows for misuse.
    this.inputTarget.addEventListener(
      'change',
      this.handleFilesSelected.bind(this),
    )
    this.renderPersistedDocuments()
  }

  disconnect() {
    super.disconnect()
    this.inputTarget.removeEventListener(
      'change',
      this.handleFilesSelected.bind(this),
    )
  }

  hiddenInput(name: string, index: number, value: string) {
    const input = window.document.createElement('input')
    input.type = 'hidden'
    input.name = `${this.fieldNameValue}[${index}][${name}]`
    input.value = value

    return input
  }

  hiddenFileInput(name: string, index: number, file: File) {
    const input = window.document.createElement('input')
    input.type = 'file'
    input.name = `${this.fieldNameValue}[${index}][${name}]`
    input.files = this.convertToFilesList([file])
    input.className = 'documents-field__input'

    return input
  }

  documentPreview(doc: Document, onRemove: () => void = () => {}) {
    const li = window.document.createElement('li')
    li.className = 'documents-field__preview-item'

    const fileName = window.document.createElement('span')
    fileName.className = 'documents-field__preview-file-name'
    fileName.textContent = doc.filename

    const img = this.fileTypeIcon(doc.type)

    const removeButton = window.document.createElement('button')
    removeButton.className = 'documents-field__preview-remove'
    removeButton.addEventListener('click', () => {
      li.remove()
      onRemove()
    })
    const deleteIcon = window.document.createElement('img')
    deleteIcon.src = this.removeIconValue
    deleteIcon.alt = this.removeIconAltValue
    removeButton.appendChild(deleteIcon)

    li.appendChild(img)
    li.appendChild(fileName)
    li.appendChild(removeButton)

    return li
  }

  fileTypeIcon(type: string) {
    const img = window.document.createElement('img')
    img.className = 'documents-field__preview-image'
    img.alt = ''
    img.ariaHidden = 'true'
    img.height = 42
    img.tabIndex = -1
    img.width = 42

    if (type === 'application/pdf') {
      img.src = this.pdfIconValue
    } else if (type.startsWith('image/')) {
      img.src = this.imageIconValue
    } else {
      img.src = this.docIconValue
    }

    return img
  }

  renderPersistedDocuments() {
    this.persistedDocumentsValue.forEach((doc) => {
      const index = this.documentIndex++
      const input = this.hiddenInput('id', index, doc.id)
      this.hiddenInputsTarget.appendChild(input)

      const removePersistedDocument = () => {
        const destroyFlag = this.hiddenInput('_destroy', index, '1')
        this.hiddenInputsTarget.appendChild(destroyFlag)
      }
      const preview = this.documentPreview(doc, removePersistedDocument)
      this.previewsTarget.appendChild(preview)
    })
  }

  handleFilesSelected() {
    const newFiles = Array.from(this.inputTarget.files)
    newFiles.forEach((file) => {
      const li = this.documentPreview({
        filename: file.name,
        type: file.type,
      })
      const fileInput = this.hiddenFileInput('file', this.documentIndex++, file)
      li.appendChild(fileInput)

      this.previewsTarget.appendChild(li)
    })
  }

  convertToFilesList(files: File[]): FileList {
    const dataTransfer = new DataTransfer()
    files.forEach((file) => dataTransfer.items.add(file))
    return dataTransfer.files
  }
}
