export function getMbPub(): string {
  const metaTag = document.head.querySelector(
    'meta[name="mb_pub"]',
  ) as HTMLMetaElement

  return metaTag.content
}

export function getMbST(): string {
  const metaTag = document.head.querySelector(
    'meta[name="mb_st"]',
  ) as HTMLMetaElement

  return metaTag.content
}

export const latlongRegExp = new RegExp(
  /^([-]?(90|[1-8]?\d(\.\d+)?)),([-]?(180|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/,
)
