import { DatePicker } from 'antd'

import { ComponentController } from './component_controller'

export class DatePickerController extends ComponentController {
  get component() {
    return DatePicker
  }

  get initialProps() {
    return {
      ...this.propsFromData,
      onChange: this.onChange.bind(this),
    }
  }

  onChange(x: any) {
    this.dispatch('value-change', { detail: x?.$d.toDateString() || '' })
  }
}
