import { Controller } from '@hotwired/stimulus'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipe, { PreparedPhotoSwipeOptions } from 'photoswipe'

export class PhotoswipeController extends Controller<HTMLElement> {
  static values = {
    options: Object,
  }

  declare lightbox: PhotoSwipeLightbox
  declare readonly optionsValue: Partial<PreparedPhotoSwipeOptions>

  connect() {
    this.lightbox = new PhotoSwipeLightbox({
      pswpModule: PhotoSwipe,
      gallery: this.element,
      children: 'a',
      ...this.defaultOptions(),
      ...this.optionsValue,
    })
    this.lightbox.init()
  }

  disconnect() {
    this.lightbox.destroy()
  }

  defaultOptions(): Partial<PreparedPhotoSwipeOptions> {
    return {}
  }

  photoswipeAnchor(url: string, width: string, height: string) {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.dataset.pswpWidth = width
    anchor.dataset.pswpHeight = height
    return anchor
  }
}
