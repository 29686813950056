import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { createElement } from 'react'

import { ComponentController } from './component_controller'

type IconType = 'edit' | 'duplicate' | 'delete'

type HttpMethod = 'get' | 'delete'

const iconMap: Record<IconType, JSX.Element> = {
  edit: createElement(EditOutlined),
  duplicate: createElement(CopyOutlined),
  delete: createElement(DeleteOutlined),
}

type QuickActionsProps = {
  actions: {
    iconType: IconType
    label: string
    url: string
    method: HttpMethod
  }[]
}

const QuickActions = ({ actions }: QuickActionsProps) => {
  const items: MenuProps['items'] = actions.map(
    ({ iconType, label, url, method }) => ({
      icon: iconMap[iconType],
      key: url,
      label: createElement(
        'a',
        { href: url, 'data-turbo-method': method },
        label,
      ),
    }),
  )
  const icon = createElement(MoreOutlined)
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'], arrow: true },
    icon,
  )
}

export class QuickActionsController extends ComponentController {
  get component() {
    return QuickActions
  }
}
