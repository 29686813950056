export function urlWithQueryParams(
  baseUrl: string,
  formData: FormData,
): string {
  const searchParams = new URLSearchParams()

  for (const [key, value] of formData) {
    if (typeof value === 'string' && value.trim() !== '') {
      searchParams.append(key, value)
    }
  }

  const queryString = searchParams.toString()
  return baseUrl + (queryString ? `?${queryString}` : '')
}
