import { Controller } from '@hotwired/stimulus'

import { targetUpdate } from './components/component_controller'
import { HTMLElementEvent } from '../types'

export class ToggleDisabledController extends Controller {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  toggle(event: HTMLElementEvent<HTMLInputElement>) {
    const disabled = !event.target.checked
    if (disabled) {
      targetUpdate(this.inputTarget, { disabled: true, value: '' })
    } else {
      targetUpdate(this.inputTarget, { disabled: false })
    }
  }
}
