import { Controller } from '@hotwired/stimulus'
import { HTMLElementEvent } from '../types/dom'

export class DependentSelectsController extends Controller {
  dispatchResetEvent(event: HTMLElementEvent<HTMLInputElement>) {
    this.dispatch(`reset`, {
      detail: { content: { options: [], value: null } },
    })
  }
}
