import { BindingController, pushBinding } from './binding_controller'

import { DispatchEvent } from '../types'

export class LocationLegendBindingController extends BindingController {
  static targets = ['primaryContactDetail']

  static values = { primaryContactDefault: String }

  declare readonly primaryContactDetailTarget: HTMLElement
  declare readonly primaryContactDefaultValue: string

  connect() {
    if (this.primaryContactDefaultValue) {
      this.showDetail(this.primaryContactDetailTarget)
    }
  }

  sync(value: string) {
    if (value) {
      pushBinding({
        controller: this,
        attribute: 'set-primary-contact-name',
        value,
        prefix: 'string-binding',
      })
      this.showDetail(this.primaryContactDetailTarget)
    } else {
      this.hideDetail(this.primaryContactDetailTarget)
    }
  }

  private showDetail(element: Element) {
    element.classList.add('visible')
  }

  private hideDetail(element: Element) {
    element.classList.remove('visible')
  }
}
