import { Controller } from '@hotwired/stimulus'
import { createElement } from 'react'
import { createRoot } from 'react-dom/client'

interface StimulusEvent<T> extends Event {
  detail: {
    content: T
  }
}

export const targetUpdate = <T>(
  target: HTMLElement,
  props: Partial<T>,
): void => {
  target.dispatchEvent(
    new CustomEvent('update', { detail: { content: props } }),
  )
}

export class ComponentController<T extends {} = {}> extends Controller {
  props: Partial<T> = {}
  reactRoot = null

  connect() {
    this.reactRoot = createRoot(this.rootElement)
    this.props = this.initialProps
    this.render(this.props)
    this.element.addEventListener('update', this.update.bind(this))
  }

  disconnect() {
    this.reactRoot.unmount()
    this.element.removeEventListener('update', this.update.bind(this))
  }

  getPropsFromEvent(event: StimulusEvent<{}>) {
    return event.detail.content
  }

  render(props: Partial<T>) {
    this.reactRoot.render(createElement(this.component, props, this.children))
  }

  update(event: StimulusEvent<{}>) {
    this.updateProps(this.getPropsFromEvent(event))
  }

  updateProps(partialProps: Partial<T>) {
    this.props = { ...this.props, ...partialProps }
    this.render(this.props)
  }

  get children() {
    return null
  }

  get component() {
    return undefined
  }

  get propsFromData() {
    return JSON.parse(this.data.get('props'))
  }

  get initialProps() {
    return this.propsFromData
  }

  get rootElement() {
    return this.element
  }
}
