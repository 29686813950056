import { Pagination } from 'antd'
import { Turbo } from '@hotwired/turbo-rails'

import { ComponentController } from './component_controller'

export class PaginationController extends ComponentController {
  static values = {
    totalText: String,
  }

  declare readonly totalTextValue: string

  get component() {
    return Pagination
  }

  get initialProps() {
    return {
      align: 'center',
      pageSizeOptions: [5, 10, 20, 50],
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: () => this.totalTextValue,
      ...this.propsFromData,
      onChange: this.handleChange.bind(this),
    }
  }

  handleChange(page: number, pageSize: number) {
    const url = new URL(window.location.href)
    url.searchParams.set('page', page.toString())
    url.searchParams.set('items', pageSize.toString())
    Turbo.visit(url.toString())
  }
}
