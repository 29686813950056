import { BoundValue, BindingController } from './binding_controller'
import { DispatchEvent } from '../types'

export class StringBindingController extends BindingController {
  static values = { default: String }

  declare readonly defaultValue: string

  extract(event: DispatchEvent<BoundValue<string>>): string {
    return event.detail.value || this.defaultValue
  }
}
