import { Controller } from '@hotwired/stimulus'

export class LabelController extends Controller {
  static values = {
    required: String,
  }

  declare readonly requiredValue: string

  toggleClasses(event) {
    if (event.detail.value == this.requiredValue) {
      this.element.classList.add('label--required')
    } else {
      this.element.classList.remove('label--required')
    }
  }
}
