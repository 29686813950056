import { Controller } from '@hotwired/stimulus'
import {
  targetUpdate,
  ComponentController,
} from './components/component_controller'

interface StimulusEvent<T> extends Event {
  detail: {
    value: T
  }
}

export class TerritoryFormController extends Controller {
  static values = { states: String }

  static targets = ['states', 'counties', 'cities', 'postalCodes']

  declare multiSelectOutlets: ComponentController[]
  declare statesValue: string

  declare readonly statesTarget: HTMLElement
  declare readonly countiesTarget: HTMLElement
  declare readonly citiesTarget: HTMLElement
  declare readonly postalCodesTarget: HTMLElement

  states: string = ''

  connect() {
    super.connect()
    this.states = this.statesValue
  }

  async updateCountries(event: StimulusEvent<string>) {
    const countries = event.detail.value
    const { options } = await this.fetchStates(countries)
    this.updateStatesProps(options, options.length === 0)
  }

  async updateStates(event: StimulusEvent<string>) {
    const states = event.detail.value
    this.states = states
    const { options } = await this.fetchCounties(states)
    this.updateCountiesProps(options, options.length === 0)
  }

  async updateCounties(event: StimulusEvent<string>) {
    const counties = event.detail.value
    const { options } = await this.fetchCities(this.states, counties)
    this.updateCitiesProps(options, options.length === 0)
  }

  async updateCities(event: StimulusEvent<string>) {
    const cities = event.detail.value
    const { options } = await this.fetchPostalCodes(this.states, cities)
    this.updatePostalCodesProps(options, options.length === 0)
  }

  updateStatesProps(
    options: { label: string; value: string }[],
    disabled = false,
  ) {
    targetUpdate(this.statesTarget, { disabled, options, value: [] })
    this.updateCountiesProps([], true)
  }

  updateCountiesProps(
    options: { label: string; value: string }[],
    disabled = false,
  ) {
    targetUpdate(this.countiesTarget, { disabled, options, value: [] })
    this.updateCitiesProps([], true)
  }

  updateCitiesProps(
    options: { label: string; value: string }[],
    disabled = false,
  ) {
    targetUpdate(this.citiesTarget, { disabled, options, value: [] })
    this.updatePostalCodesProps([], true)
  }

  updatePostalCodesProps(
    options: { label: string; value: string }[],
    disabled = false,
  ) {
    targetUpdate(this.postalCodesTarget, { disabled, options, value: [] })
  }

  private fetchStates(countries: string) {
    return fetch(`/postal_code_data/states?countries=${countries}`).then(
      (response) => response.json(),
    )
  }

  private fetchCounties(states: string) {
    return fetch(`/postal_code_data/counties?states=${states}`).then(
      (response) => response.json(),
    )
  }

  private fetchCities(states: string, counties: string) {
    return fetch(
      `/postal_code_data/cities?counties=${counties}&states=${states}`,
    ).then((response) => response.json())
  }

  private fetchPostalCodes(states: string, cities: string) {
    return fetch(
      `/postal_code_data/postal_codes?cities=${cities}&states=${states}`,
    ).then((response) => response.json())
  }
}
