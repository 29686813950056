import { NavigationControl, Popup } from 'mapbox-gl'

import { Geocodeable } from '../types'
import { LocalClient } from '../clients'
import { MapboxController } from './mapbox_controller'
import Supercluster from 'supercluster'

export class SearchableMap extends MapboxController {
  static targets = ['searchableMap']
  declare readonly searchableMapTarget: HTMLElement
  static mapIconPath = '/images/icons/marker-blue.png'

  connect() {
    this.setTokens()
    this.initializeMap(this.searchableMapTarget)
    this.initializeMapImages()
    this.map.addControl(new NavigationControl())

    this.initializeLayers()
  }

  private initializeMapImages() {
    this.map.loadImage(SearchableMap.mapIconPath, (error, image) => {
      if (error) throw error
      this.map.addImage('custom-marker', image)
    })
  }

  private async initializeLayers() {
    const geocodeables = await LocalClient.queryCurrentPath<Geocodeable[]>({
      preserveExistingParams: true,
    })

    let geojson = geocodeables.map((geocodeable) => {
      return this.geocodeableToGeoJson(geocodeable)
    })

    // Supercluster is a library that clusters points on a map
    // Functionality is built into Mapbox but is not as customizable
    const cluster = new Supercluster({
      radius: 50, // Cluster radius in pixels
      maxZoom: 16, // Maximum zoom level for clustering
      minZoom: 0, // Minimum zoom level
    })

    cluster.load(geojson)

    this.map.addSource('clusters', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: geojson }, // Placeholder
    })

    // Layer containing circles of cluster data
    this.map.addLayer({
      id: 'cluster-layer',
      type: 'circle',
      source: 'clusters',
      filter: ['has', 'point_count'], // Only show clusters
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          10,
          '#f1f075',
          25,
          '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 10, 30, 25, 40],
      },
    })

    // Layer container cluster counts, rendered on top of cluster circles
    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'clusters',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    })

    // Layer containing singular, unclustered points
    // Markers can't be used here because they don't support clustering
    this.map.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'clusters',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'custom-marker',
        'icon-size': 0.25,
      },
    })

    // Symbol layers have issues being clicked so this layer is added to handle clicks
    this.map.addLayer({
      id: 'unclustered-point-event-layer',
      type: 'circle',
      source: 'clusters',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 40,
        'circle-opacity': 0,
      },
    })

    this.map.on('moveend', (e) => {
      this.updateClusters(cluster)
    })
    this.updateClusters(cluster)

    this.map.on('click', 'unclustered-point-event-layer', (e) => {
      this.handleUnclusteredPointClick(e)
    })
  }

  private updateClusters(cluster) {
    const zoom = this.map.getZoom()
    const bounds = this.map.getBounds()
    const clusters = cluster.getClusters(
      [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ],
      Math.round(zoom),
    )

    ;(this.map.getSource('clusters') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: clusters,
    })
  }

  private handleUnclusteredPointClick(e) {
    // Get the clicked feature's properties
    const properties = e.features[0].properties

    // Create a new popup
    const popup = new Popup({ offset: 25 })
      .setLngLat(e.features[0].geometry.coordinates) // Set popup coordinates
      .setHTML(
        `
                 <h3>${properties.name}</h3>
                 <p>${properties.uuid}</p>
             `,
      ) // Use the feature's properties for content
      .addTo(this.map) // Add the popup to the map
  }

  private geocodeableToGeoJson(
    geocodeable: Geocodeable,
  ): GeoJSON.Feature<GeoJSON.Geometry> {
    const [latitude, longitude] = geocodeable.latlong.split(',').map(Number)
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
      properties: {
        name: geocodeable.name,
        uuid: geocodeable.uuid,
      },
    }
  }
}
