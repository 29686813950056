import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import {
  CheckCircleOutlined,
  MoreOutlined,
  PauseOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import * as Turbo from '@hotwired/turbo'
import { createElement, ReactElement } from 'react'

import { ComponentController } from './component_controller'

type IconType = 'activate' | 'deactivate' | 'suspend'

const iconMap: Record<IconType, ReactElement> = {
  activate: createElement(CheckCircleOutlined),
  deactivate: createElement(PoweroffOutlined),
  suspend: createElement(PauseOutlined),
}

type BulkActionsProps = {
  selector: string
  actions: {
    iconType: IconType
    label: string
    status: string
    confirmationMessage: string
  }[]
}

const BulkActions = ({ selector, actions }: BulkActionsProps) => {
  const items: MenuProps['items'] = actions.map(
    ({ iconType, status, label, confirmationMessage }) => ({
      icon: iconMap[iconType],
      key: status,
      label: createElement(
        'a',
        { onClick: () => sendValues(status, selector, confirmationMessage) },
        label,
      ),
    }),
  )
  const icon = createElement(MoreOutlined)
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'], arrow: true },
    icon,
  )
}
const sendValues = (
  status: string,
  selector: string,
  confirmationText: string,
): void => {
  // Gather all checked checkbox values
  const selectedValues = Array.from(document.querySelectorAll(selector))
    .filter((checkbox: HTMLInputElement) => checkbox.checked)
    .map((checkbox: HTMLInputElement) => checkbox.value)

  if (confirm(confirmationText)) {
    updateStatuses(status, selectedValues)
  } else {
    // Optional: handle the cancellation here if needed
    console.log('Operation canceled by user.')
  }
}

const updateStatuses = (status: string, uuids: string[]) => {
  // Prepare the CSRF token
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = csrfMetaTag ? csrfMetaTag.getAttribute('content') : null
  const params = new URLSearchParams(window.location.search)

  fetch(`users/update_statuses`, {
    method: 'PATCH',
    headers: {
      Accept: 'text/vnd.turbo-stream.html, application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken || '',
    },
    body: JSON.stringify({
      status: status,
      uuids: uuids,
      page: params.get('page'),
      items: params.get('items'),
    }),
  })
    .then((response) => response.text())
    .then((turboStreamResponse) => {
      // If the response is a Turbo Stream template, apply it.
      // `Turbo.renderStreamMessage` will process the stream and
      // update the DOM accordingly.
      if (turboStreamResponse.includes('<turbo-stream')) {
        Turbo.renderStreamMessage(turboStreamResponse)
      } else {
        console.warn(
          'Received a non-turbo-stream response:',
          turboStreamResponse,
        )
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}
export class BulkActionsController extends ComponentController {
  get component() {
    return BulkActions
  }
}
