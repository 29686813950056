import { Table, Transfer } from 'antd'
import { createElement, useMemo } from 'react'

import { ComponentController } from './component_controller'

const buildFilterOption = (columns) => (input: string, item) => {
  const searchInput = input.toLowerCase()
  return columns.some((column) => {
    const value = item[column.dataIndex]
    return value?.toLowerCase().includes(searchInput)
  })
}

const buildTransferColumnTable = (columns) => {
  const TransferColumnTable = ({
    filteredItems,
    onItemSelect,
    onItemSelectAll,
    selectedKeys: listSelectedKeys,
  }) => {
    const onRow = ({ key, disabled: itemDisabled }) => ({
      onClick: () => {
        if (itemDisabled) {
          return
        }
        onItemSelect(key, !listSelectedKeys.includes(key))
      },
    })
    const rowSelection = {
      getCheckboxProps: (item) =>
        ({
          'aria-label': item.identifier,
        }) as any,
      hideSelectAll: false,
      onChange(selectedRowKeys) {
        onItemSelectAll(selectedRowKeys, 'replace')
      },
      selectedRowKeys: listSelectedKeys,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    }
    return createElement(Table, {
      columns,
      dataSource: filteredItems,
      onRow,
      pagination: false,
      rowSelection,
      size: 'small',
    })
  }
  return TransferColumnTable
}

const TableTransfer = ({ columns, ...rest }) => {
  const filterOption = useMemo(() => buildFilterOption(columns), [columns])
  const props = {
    columns,
    filterOption,
    showSearch: true,
    ...rest,
  }
  const TransferColumnTable = useMemo(
    () => buildTransferColumnTable(columns),
    [columns],
  )
  return createElement(Transfer, props, TransferColumnTable as any)
}

export class TableTransferController extends ComponentController {
  static targets = ['component', 'input']

  targetKeys = []

  declare componentTarget: HTMLElement
  declare inputTarget: HTMLInputElement

  onChange = (targetKeys, direction, moveKeys) => {
    let maybeInvertedKeys = targetKeys
    if (direction === 'right') {
      maybeInvertedKeys = maybeInvertedKeys.filter(
        (key) => !moveKeys.includes(key),
      )
      maybeInvertedKeys = maybeInvertedKeys.concat(moveKeys)
    }
    this.inputTarget.value = maybeInvertedKeys.join(',')
    this.targetKeys = maybeInvertedKeys
    this.updateProps({ targetKeys: maybeInvertedKeys })
  }

  get component(): any {
    return TableTransfer
  }

  get initialProps() {
    return {
      onChange: this.onChange,
      showSelectAll: false,
      ...this.propsFromData,
    }
  }

  get rootElement() {
    return this.componentTarget
  }
}
