import mapboxgl, { Marker } from 'mapbox-gl'

import { DispatchEvent } from '../types'
import { DEFAULT_INITIAL_ZOOM } from '../constants'
import { MapboxAddress } from '../models'
import { MapboxController } from './mapbox_controller'

export class GeocodeableAddressableMapController extends MapboxController {
  verifiedMarker?: Marker = undefined
  static targets = ['map']
  declare readonly mapTarget: HTMLInputElement
  declare readonly hasMapTarget: boolean

  connect() {
    this.setTokens()
    this.initializeMap(this.mapTarget)
  }

  handleSuggestedAddressSelectionEvent({
    detail: address,
  }: DispatchEvent<MapboxAddress>) {
    if (address.coordinates) {
      this.resetMarkers(address.latitude, address.longitude)
    }
  }

  handleAddressLatlongChangeEvent({ detail: latlong }: DispatchEvent<string>) {
    const [latitude, longitude] = latlong.split(',')
    this.setVerifiedMarker(latitude, longitude)
  }

  clear() {
    this.clearMarkers()

    this.flyToPoint({
      zoom: DEFAULT_INITIAL_ZOOM,
    })
  }

  protected resetMarkers(latitude: string, longitude: string) {
    this.setVerifiedMarker(latitude, longitude)
  }

  protected setVerifiedMarker(
    latitude: string,
    longitude: string,
    options = {},
  ) {
    this.clearMarkers()
    this.verifiedMarker = this.addMarker(latitude, longitude, options)
    this.flyToMarkers()
  }
}
