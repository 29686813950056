import { Dropdown, Space } from 'antd'
import { DownOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { createElement } from 'react'

import { ComponentController } from './component_controller'

type IconType = 'import' | 'plus'

const iconMap: Record<IconType, JSX.Element> = {
  import: createElement(ImportOutlined),
  plus: createElement(PlusOutlined),
}

type ButtonDropdownProps = {
  actions: { icon?: IconType; label: string; url: string }[]
  label: string
}

type ButtonDropdownTargetProps = {
  className?: string
  label: string
}

const ButtonDropdownTarget = ({
  className,
  label,
  ...rest
}: ButtonDropdownTargetProps) =>
  createElement(
    'button',
    {
      className: `button button--medium button--primary ${className}`,
      ...rest,
    },
    createElement(Space, null, label, createElement(DownOutlined)),
  )

const ButtonDropdown = ({ actions, label }: ButtonDropdownProps) => {
  const items: MenuProps['items'] = actions.map(({ icon, label, url }) => ({
    key: url,
    icon: icon ? iconMap[icon] : undefined,
    label: createElement('a', { href: url, 'data-turbo': false }, label),
  }))
  const button = createElement(ButtonDropdownTarget, { label })
  return createElement(
    Dropdown,
    { menu: { items }, trigger: ['click'] },
    button,
  )
}

export class ButtonDropdownController extends ComponentController {
  get component() {
    return ButtonDropdown
  }
}
